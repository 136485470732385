
import gipImage from "../assets/A360_GIP.jpg";
import { formatCurrency } from '../util/formatCurrency';

type LineItemProps = {
  name:string;
  description:string;
  price:number;
  id:string;
}

export default function LineItem(item:LineItemProps) {

    return (
    <li key={item.id} className="flex py-6">
        <div className="flex-shrink-0">
          <img
            src={gipImage}
            className="h-24 w-24 rounded-md object-cover object-center sm:h-32 sm:w-32"
          />
        </div>

        <div className="ml-4 flex flex-1 flex-col sm:ml-6">
          <div>
            <div className="flex justify-between">
              <h4 className="text-base mr-5">
                <a className="font-medium text-gray-700 hover:text-gray-800">
                  {item.name}
                </a>
              </h4>
              <p className="ml-4 text-base font-medium text-gray-900">{formatCurrency(item.price)}</p>
            </div>
            <p className="mt-1 text-base text-gray-500">{item.description}</p>
            {/* <p className="mt-1 text-base text-gray-500">{product.size}</p> */}
          </div>

        </div>
      </li>
    )
}