import { httpsCallable } from "firebase/functions"
import { useState } from "react"
import { functions } from "../firebase.config"

interface Props {
    dealId: string
}
const useStripeRenewalPayment = (props: Props) => {

    const [submitting, setSubmitting] = useState(false);
    const [error, setError] = useState('');

    const initPayment = async (productId: string) => {
        try {
            if (submitting) return
            setSubmitting(true)
            const result = await httpsCallable<any, { redirectUrl: string }>(
                functions,
                "initStripeCheckoutRenewalSession"
            )({ dealId: props.dealId, productId: productId });
            setSubmitting(false)
            if (result.data.redirectUrl) {
                window.location.href = result.data.redirectUrl
            }
        } catch (error) {
            setSubmitting(false)
            setError((error as Error).message)
        }
    }

    return { initPayment, submitting, error }
}

export default useStripeRenewalPayment