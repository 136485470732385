import React from 'react';
import './App.css';
import Layout from './Layout';
import Payment from './Payment';
import PaymentConfirmation from './PaymentConfirmation';
import { useState } from 'react';
import HomePageLayout from './layouts/HomePageLayout';
import LongevityPlatinum from './LongevityPlatinum';

function App() {
  const isDealConfirmation = /\/payment\/confirmation/.test(window.location.pathname);
  const isDealPayment = /\/payment\/deal\/[A-Za-z0-9+]/.test(window.location.pathname);
  const isLongevityPlatinum = /\/programs/.test(window.location.pathname);
  const [errorMessage, setErrorMessage] = useState('');


  return (
    <div className="App">
      <HomePageLayout>
        <>
          {isDealPayment && <Payment />}
          {isDealConfirmation && <PaymentConfirmation />}
          {errorMessage && <p>{errorMessage}</p>}
          {isLongevityPlatinum && <LongevityPlatinum />}
        </>
      </HomePageLayout>
    </div>
  );
}

export default App;
