import React, { useState } from 'react';
import RenewalMembership, { RenewalMembershipProps } from './RenewalMembership';
import { Deal } from '../../types/deal.type';
import useStripeRenewalPayment from '../../hooks/stripe-renewal-payment';
import StripeProductIds from '../../constants/stripe-products';
import { RenewalLevel } from '../../constants/data.enums';

const VirtualMembership: React.FC<{ deal: Deal }> = ({ deal }) => {
    const { initPayment, error } = useStripeRenewalPayment({ dealId: deal.hubspot_deal_id })
    const [btnStatuses, setBtnStatuses] = useState<Record<number, boolean>>({ 1: false, 2: false })


    const patchBtnStatuses = (newState: Record<number, boolean>) => {
        setBtnStatuses((curState) => {
            return { ...curState, ...newState }
        })
    }

    const config: RenewalMembershipProps = {
        title: 'Global Virtual',
        renewalOptions: {
            priceAfterwards: '10,000',
            oneYearFee: '9,500',
            sixYearFee: '9,000'
        },
        commitTo: {
            discountedFee: '9,500',
            fee: '10,000',
            save: '500',
            year: '2025',
            desc:"*** Global Virtual memberships include livestream access for 2 of your colleagues or friends.",
            actionBtns: [{
                text: 'SECURE YOUR SPOT NOW',
                submitting: btnStatuses[1],
                onClick: () => {
                    patchBtnStatuses({ 1: true });
                    initPayment(StripeProductIds[RenewalLevel.VIRTUAL].id).finally(() => {
                        patchBtnStatuses({ 1: false });
                    })
                }
            }]
        },
        commitThru: {
            save: '6,000',
            year: '2030',
            discountedFee: '9,000',
            fee: '10,000',
            desc: `*** Global Virtual memberships include livestream access for 2 of your colleagues or friends.<br/><br/> We accept only ACH or credit card payments. The next Membership Payment is due and will be auto charged on March 1, 2025, for the 2026 membership, and thereafter on the March 1st anniversary through 2029.`,
            actionBtns: [{
                text: 'SECURE YOUR SPOT NOW',
                submitting: btnStatuses[2],
                onClick: () => {
                    patchBtnStatuses({ 2: true });
                    initPayment(StripeProductIds[RenewalLevel.VIRTUAL + 'Bundle'].id).finally(() => {
                        patchBtnStatuses({ 2: false });
                    })
                }
            }],
        }
    }

    return <RenewalMembership {...config} paymentError={error} />
}

export default VirtualMembership