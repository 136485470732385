import { useForm, SubmitHandler } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { httpsCallable } from 'firebase/functions';
import { functions } from "../firebase.config";
import { PulseLoader } from 'react-spinners'

type FormData = {
  email: string;
  email_2: string;
  deal_id: string;
};

export const RequestInvoiceForm = ({setShowInvoice, deal}:any) => {
const { register, handleSubmit, reset, formState: { errors, isSubmitting, isLoading }} = useForm<FormData>()
const [serverError, setServerError] = useState<string>("")
const [isSubmitted, setIsSubmitted] = useState(false)
const [alreadyHasInvoice, setAlreadyHasInvoice] = useState(false)
const [altEmailWanted, setAltEmailWanted] = useState(false)

  const onSubmit: SubmitHandler<FormData> = async (formInputData) => {
    if(isSubmitting) return;
    await httpsCallable(
        functions,
        "submitInvoiceRequest"
      )(formInputData)
      .then(result => {
        console.log(result.data);
        setIsSubmitted(true);
        reset();
      })
      .catch(error => {
        console.error(error);
        setServerError(error.message);
      });
  }

  useEffect(() => {
    if(deal.deal.quickbooks_invoice_id !== undefined && deal.deal.quickbooks_invoice_id.length > 0){
      setAlreadyHasInvoice(true)
    }
  },[])

  const showAltEmail = () => {
    if(!altEmailWanted){
      return "hidden"
    }else{
      return "block"
    }
  }

  const formCss = () => {
    if(isSubmitted){
      return "relative flex flex-col mt-14 mb-auto space-y-5 hidden"
    }else{
      return "relative flex flex-col mt-14 mb-auto space-y-5"
    }
  }

  const bodyCss = () => {
    if(!isSubmitted){
      return "hidden"
    }else{
      return "block"
    }
  }
  const openInvoice = () => {
      window.open(deal.deal.quickbooks_invoice_link, "_blank", "noreferrer");
  }

  const hasInvoiceLink = () => {
    return deal.deal.quickbooks_invoice_link ? true : false;
  }

  return (
    <>
    <div className={bodyCss()}>
        <div className="text-center font-large bg-green-200 border-r-4 text-gray-800 mb-4 mt-10 p-5">Your invoice has been sent!</div>
    </div>

    {alreadyHasInvoice && 
      <>
          <div className="mx-auto max-w-2xl px-4 pb-6 sm:px-6 lg:px-0 mt-6 mb-6">
              <div className="bg-blue-100 border border-blue-500 text-blue-700 px-4 py-3 mb-6 rounded relative text-center" role="alert">
                  <strong className="font-bold">Invoice Already Created!</strong>
              </div>
              {hasInvoiceLink() &&
                <button onClick={() => openInvoice()} className="w-full btn btn-primary">Click Here to View Invoice</button>
              }
          </div>
      </>
    }

    {!alreadyHasInvoice &&
      <form onSubmit={handleSubmit(onSubmit)} className={formCss()}>

          {serverError && (
              <div className="bg-red-100 border-t border-b border-red-500 text-red-700 px-4 py-3" role="alert">
                  <p className="font-bold">Server Error</p>
                  <p className="text-sm">An error occured when submitting the form.</p>
              </div>
          )}

        <div>
          <input type="hidden" {...register("deal_id")} value={deal.deal.hubspot_deal_id}/>
          <div className="block text-center font-medium text-gray-700 mb-4">Click submit below and we'll email you an invoice at {deal.deal.owner_email}.</div>
          <div className="block text-center font-medium text-gray-700 mb-4">The invoice will include wire transfer instructions.</div>
          <input
            type="hidden"
            {...register("email", { required: true })} value={deal.deal.owner_email}
            className="mt-1 p-2 border rounded-md w-full"
          />
          {errors.email && <p className="text-red-500 text-xs">Email is required.</p>}
        </div>
        <div><p className="mt-1 cursor-pointer mt-2 text-sm text-blue-500" onClick={() => {setAltEmailWanted(!altEmailWanted)}}>Need to send to another email?</p></div>
        <div className={showAltEmail()}>
          <label className="block text-sm font-medium text-gray-700">Additional / Alternate Email</label>
          <small className="block text-xs text-gray-500 mb-1">Another email to copy (optional)</small>
          <input
            type="email"
            {...register("email_2")}
            className="mt-1 p-2 border rounded-md w-full"
          />
        </div>
        <div className="grid grid-cols-2 gap-4">
          <div>
              <button onClick={() => {setShowInvoice(false)}} className='btn btn-primary w-full'>
                  Back
              </button>
          </div>
          <div>  
              <button type="submit" className="w-full btn btn-primary">
                  {isSubmitting ? <PulseLoader color="#000" size={10} /> : "Submit"}
              </button>

          </div>
        </div>
      </form>
    }
    </>
  );
};
