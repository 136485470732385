import { Fragment, useEffect, useState } from 'react'
import HeaderNull from './Layout'
import gipImage from "./assets/A360_GIP.jpg";
import { httpsCallable, HttpsCallableResult} from "firebase/functions";
import { functions } from "./firebase.config";
import { RequestInvoiceForm } from './components/RequestInvoiceForm'
import { PulseLoader } from 'react-spinners'
import { formatCurrency } from './util/formatCurrency';
import LineItem from './components/LineItem';

export default function PaymentConfirmation() {
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingPage, setIsLoadingPage] = useState(true)
  const [deal, setDeal] = useState({} as any)
  const [confirmationInfo, setConfirmationInfo] = useState({} as any)
  const [member, setMember] = useState({} as any)
  const [nextSteps, setNextSteps] = useState("")
  const [stripeSessionId, setStripeSessionId] = useState("" as any)

    const goToCreateAccount = () => {
        const urlBase = process.env.REACT_APP_ABUNDANCEHUB_URL ?? "https://hub.a360.com"
        window.location.href = urlBase + "/access/" + member.uid
    }

    useEffect(() => {
        let queryString = window.location.search
        let params = new URLSearchParams(queryString);
        let stripeSessionIdInt = params.get('session_id')
        setStripeSessionId(stripeSessionIdInt)

        const getStripeConfirmationHook = async () => {
            const response = await httpsCallable(functions,"getStripeConfirmationOnCall")({ session_id:stripeSessionIdInt});
            return response.data
        }
        let dealObject = getStripeConfirmationHook().then((data:any) => {
            if(data.status == "error"){
                console.log(data)
               window.location.href = `/payment/deal/${data.deal.hash}?failed_pi=${data.payment_intent_id}`
            }else{
                setConfirmationInfo(data.confirmation)
                setMember(data.member)
                setNextSteps(data.nextSteps)
                setIsLoadingPage(false)
            }
        })
    }, [])

  return (
    <>
    
    <div className="bg-white">
      <main>
      {isLoadingPage && <div className="text-center mt-[300px]"><PulseLoader color="#000" size={40} /></div>}
      {!isLoadingPage &&
       <div className="mx-auto max-w-6xl px-4 py-16 sm:px-6 sm:py-12 lg:px-0">
        <h1 className="text-center text-3xl font-bold tracking-tight text-black sm:text-4xl mb-10">{nextSteps!="Processing" ? "Payment Confirmed!" : "Payment Processing"}</h1>
        {nextSteps=="Processing" && 
            <>
                <div className="mx-auto max-w-2xl px-4 pb-6 sm:px-6 lg:px-0">
                    <div className="bg-blue-100 border border-blue-500 text-blue-700 px-4 py-3 rounded relative" role="alert">
                        <strong className="font-bold">Your bank payment is currently processing.</strong>
                        <p className="block mt-3">You will receive a confirmation email when your payment finalizes. This can take a few hours or days.</p>
                    </div>
                </div>
        
            </>
        }
            <div className="grid max-w-2xl mx-auto grid-cols-1 gap-x-20">
            {/* Left Side */}
            <div>
                <section aria-labelledby="cart-heading">
                <p className="mb-3 text-black">Order Summary</p>
                <ul role="list" className="divide-y divide-gray-200 border-b border-t border-gray-200">
                    {confirmationInfo.line_items && confirmationInfo.line_items.map((product:any) => (
                        <LineItem name={product.description} description={""} price={product.subtotal} id={product.id} />
                    ))}
                </ul>
                </section>

                <section aria-labelledby="summary-heading" className="mt-5">
                <div>
                    {confirmationInfo.amount_discount && 
                <dl className="space-y-4 mb-3">
                    <div className="flex items-center justify-between">
                        <dt className="text-base font-medium text-gray-900">Discount</dt>
                        <dd className="ml-4 text-base font-medium text-green-600">- {formatCurrency(confirmationInfo.amount_discount)}</dd>
                    </div>
                    </dl>}
                    {confirmationInfo.amount_total && 
                    <dl className="space-y-4">
                    <div className="flex items-center justify-between">
                        <dt className="text-base font-medium text-gray-900">Total</dt>
                        <dd className="ml-4 text-base font-medium text-gray-900">{formatCurrency(confirmationInfo.amount_total)}</dd>
                    </div>
                    </dl>
                    }
                </div>

                </section>

                <section aria-labelledby="summary-heading" className="mt-6">

                    <div className="rounded-lg bg-gray-100 px-6 py-6 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:px-0 lg:py-8">
                        <dl className="grid grid-cols-1 gap-6 text-sm sm:grid-cols-2 md:gap-x-8 lg:col-span-6 lg:pl-8">
                        <div>
                            <dt className="font-semibold text-gray-900">Member</dt>
                            <dd className="mt-3 text-gray-500">
                            <span className="block">{confirmationInfo.customer.name}</span>
                            <span className="block">{confirmationInfo.customer.email}</span>
                            {/* <span className="block">Toronto, ON N3Y 4H8</span> */}
                            </dd>
                        </div>
                        <div>
                            <dt className="font-semibold text-gray-900">Payment info</dt>
                            <dd className="mt-3 flex items-center">
                            {/* <div>
                                <svg aria-hidden="true" width={36} height={24} viewBox="0 0 36 24" className="h-6 w-auto">
                                <rect width={36} height={24} rx={4} fill="#224DBA" />
                                <path
                                    d="M10.925 15.673H8.874l-1.538-6c-.073-.276-.228-.52-.456-.635A6.575 6.575 0 005 8.403v-.231h3.304c.456 0 .798.347.855.75l.798 4.328 2.05-5.078h1.994l-3.076 7.5zm4.216 0h-1.937L14.8 8.172h1.937l-1.595 7.5zm4.101-5.422c.057-.404.399-.635.798-.635a3.54 3.54 0 011.88.346l.342-1.615A4.808 4.808 0 0020.496 8c-1.88 0-3.248 1.039-3.248 2.481 0 1.097.969 1.673 1.653 2.02.74.346 1.025.577.968.923 0 .519-.57.75-1.139.75a4.795 4.795 0 01-1.994-.462l-.342 1.616a5.48 5.48 0 002.108.404c2.108.057 3.418-.981 3.418-2.539 0-1.962-2.678-2.077-2.678-2.942zm9.457 5.422L27.16 8.172h-1.652a.858.858 0 00-.798.577l-2.848 6.924h1.994l.398-1.096h2.45l.228 1.096h1.766zm-2.905-5.482l.57 2.827h-1.596l1.026-2.827z"
                                    fill="#fff"
                                />
                                </svg>
                                <p className="sr-only">Visa</p>
                            </div> */}
                            <div className="m-auto">
                                {confirmationInfo.payment_method &&
                                    <>
                                        <p className="text-gray-900">{confirmationInfo.payment_method.type}</p>
                                        <p className="text-gray-900">{confirmationInfo.payment_method.line1}</p>
                                        <p className="text-gray-900">{confirmationInfo.payment_method.line2}</p>
                                    </>
                                }
                            </div>
                            </dd>
                        </div>
                        </dl>
                    </div>
                    </section>
            </div>
            {/* Right Side */}

            <div className="text-center px-6 py-6 rounded bg-green-200 mt-6 sm:mt-6">
                <div className="max-w-2xl text-3xl font-bold tracking-tight text-gray-900">
                    <h2 className="text-3xl sm:text-4xl">What's Next?</h2>
                    {nextSteps == "New Account" && <h2 className="text-xl sm:text-xl">Create Your AbundanceHub Account</h2>}
                    {nextSteps == "Processing" && <p className="text-base mt-3 sm:text-base">When your payment is completed, you will receive an email with your onboarding email with instructions to access the AbundanceHub!</p>}
                    {nextSteps == "New Account" &&
                        <>
                            <button onClick={(e) => {e.preventDefault(); goToCreateAccount()}} className="mt-4 text-base font-semibold btn btn-primary">
                                Create Your Account
                            </button>
                        </>
    
                    }
                    {nextSteps == "Existing Account" &&
                        <>
                            <button onClick={(e) => {e.preventDefault(); goToCreateAccount()}} className="mt-4 text-base font-semibold btn btn-primary">
                                Access Your Existing AbundanceHub Account
                            </button>
                        </>
                            
                    }
                </div>
            </div>
        </div>

        </div>
        }
      </main>
    </div>
    </>
  )
}
