import { useState } from "react"

interface HomePageLayoutProps {
    children?: React.ReactNode
}

const PageHeader = () => {
    const [showMenu, setShowMenu] = useState(false)

    return (<div id="pageHeader" className="outer-header-wrapper">
        <header className="header shadow-md">

            <a href="#main-content" className="header__skip">Skip to content</a>
            <div className={`header__container content-wrapper ${showMenu ? 'menu-open' : ''}`}>
                <div className="header__logo">
                    <div id="hs_cos_wrapper_site_logo" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-logo" data-hs-cos-general-type="widget" data-hs-cos-type="module">
                        <span id="hs_cos_wrapper_site_logo_hs_logo_widget" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_logo" data-hs-cos-general-type="widget" data-hs-cos-type="logo"><a href="/" id="hs-link-site_logo_hs_logo_widget" style={{ borderWidth: '0px', border: '0px' }}><img src="https://www.abundance360.com/hubfs/raw_assets/public/A360_2024_Theme/images/abundance360.svg" className="hs-image-widget " height="48" style={{ height: 'auto', width: '222px', borderWidth: '0px', border: '0px' }} width="222" alt="abundance360" title="abundance360" /></a></span>
                    </div>
                    <span className="logo-text">by Singularity University</span>
                </div>
                <div className="mobile-trigger" tabIndex={0}>
                    <svg id="menuopen" onClick={() => { setShowMenu(true) }} width="23" height="20" viewBox="0 0 23 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3.52441 5.8335H19.9878" stroke="black" strokeWidth="1.5" strokeLinecap="round"></path>
                        <path d="M3.52441 10H19.9878" stroke="black" strokeWidth="1.5" strokeLinecap="round"></path>
                        <path d="M3.52441 14.1665H19.9878" stroke="black" strokeWidth="1.5" strokeLinecap="round"></path>
                    </svg>
                    <svg id="menuclose" onClick={() => { setShowMenu(false) }} width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8.17 13.83L13.83 8.17M13.83 13.83L8.17 8.17M8 21H14C19 21 21 19 21 14V8C21 3 19 1 14 1H8C3 1 1 3 1 8V14C1 19 3 21 8 21Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                    </svg>
                </div>
                <div className="nav-wrapper" style={{ display: showMenu ? 'block' : 'none' }}>
                    <span id="hs_cos_wrapper_menu_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_menu" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="menu"><div id="hs_menu_wrapper_menu_" className="hs-menu-wrapper active-branch flyouts hs-menu-flow-horizontal" role="navigation" data-sitemap-name="default" data-menu-id="126786820726" aria-label="Navigation Menu">
                        <ul role="menu">
                            <li className="hs-menu-item hs-menu-depth-1" role="none"><a href="https://www.abundance360.com/" role="menuitem">HOME</a></li>
                            <li className="hs-menu-item hs-menu-depth-1" role="none"><a href="https://www.abundance360.com/about" role="menuitem">ABOUT</a></li>
                            <li className="hs-menu-item hs-menu-depth-1" role="none"><a href="https://www.abundance360.com/membership" role="menuitem">MEMBERSHIP</a></li>
                            <li className="hs-menu-item hs-menu-depth-1" role="none"><a href="https://longevity.abundance360.com/" role="menuitem">LONGEVITY PLATINUM</a></li>
                            <li className="hs-menu-item hs-menu-depth-1" role="none"><a href="https://www.abundance360.com/summit" role="menuitem">SUMMIT</a></li>
                        </ul>
                    </div></span>
                    <div className="login-buttons">
                        <ul>
                            {/* <li className="primary-btn ">
                                <a role="button" href="https://hub.a360.com/?__hstc=53304183.fee9aeb0d7bcfa7e72dc519bea2fb5db.1708459963841.1708634854684.1708692478739.3&amp;__hssc=53304183.1.1708692478739&amp;__hsfp=3192303745">
                                    SIGN IN
                                </a>
                            </li> */}
                            <li className="secondary-btn">
                                <a role="button" href="https://www.abundance360.com/a360-invite" style={{ backgroundColor: 'rgba(236, 187, 91,1.0)', color: 'rgba(0, 0, 0,1.0)' }}>
                                    APPLY FOR 2025
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </header>
    </div>)
}

const PageHooter = () => {
    return (<div id="pageFooter">
        <footer className="footer">
            <div className="content-wrapper">
                <div className="row-fluid">
                    <div className="span4">
                        <div className="logo">
                            <span id="hs_cos_wrapper_site_logo_hs_logo_widget" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_logo" data-hs-cos-general-type="widget" data-hs-cos-type="logo"><a href="/" id="hs-link-site_logo_hs_logo_widget" style={{ borderWidth: '0px', border: '0px' }}><img src="https://www.abundance360.com/hubfs/raw_assets/public/A360_2024_Theme/images/singularity_group.svg" className="hs-image-widget " height="48" style={{ height: 'auto', width: '222px', borderWidth: '0px', border: '0px' }} width="222" alt="Singularity group" title="Singularity group" /></a></span>
                        </div>
                        <div className="content">
                            <div id="hs_cos_wrapper_footer_text" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-rich_text" data-hs-cos-general-type="widget" data-hs-cos-type="module"><span id="hs_cos_wrapper_footer_text_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_rich_text" data-hs-cos-general-type="widget" data-hs-cos-type="rich_text"><p>So who will you meet this year? What partnerships will you form? What will you create?</p></span></div>
                            <div id="hs_cos_wrapper_module_social" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module" data-hs-cos-general-type="widget" data-hs-cos-type="module">
                                <div className="social-links">
                                    <a className="social-links__link" href="https://www.linkedin.com/company/abundance360/" target="_blank" rel="noopener">
                                        <svg version="1.0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" aria-labelledby="linkedin-in1" role="img"><title id="linkedin-in1">Follow us on LinkedIn</title><g id="linkedin-in1_layer"><path d="M100.3 480H7.4V180.9h92.9V480zM53.8 140.1C24.1 140.1 0 115.5 0 85.8 0 56.1 24.1 32 53.8 32c29.7 0 53.8 24.1 53.8 53.8 0 29.7-24.1 54.3-53.8 54.3zM448 480h-92.7V334.4c0-34.7-.7-79.2-48.3-79.2-48.3 0-55.7 37.7-55.7 76.7V480h-92.8V180.9h89.1v40.8h1.3c12.4-23.5 42.7-48.3 87.9-48.3 94 0 111.3 61.9 111.3 142.3V480z"></path></g></svg>
                                    </a>
                                    <a className="social-links__link" href="https://twitter.com/Abundance360" target="_blank" rel="noopener">
                                        <svg version="1.0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" aria-labelledby="twitter2" role="img"><title id="twitter2">Follow us on Twitter</title><g id="twitter2_layer"><path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"></path></g></svg>
                                    </a>
                                </div></div>
                        </div>
                    </div>
                    <div className="span8 footer-menu">
                        <span id="hs_cos_wrapper_fmenu_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_menu" data-hs-cos-general-type="widget" data-hs-cos-type="menu"><div id="hs_menu_wrapper_fmenu_" className="hs-menu-wrapper active-branch no-flyouts hs-menu-flow-horizontal" role="navigation" data-sitemap-name="default" data-menu-id="118480954679" aria-label="Navigation Menu">
                            <ul role="menu">
                                <li className="hs-menu-item hs-menu-depth-1 hs-item-has-children" role="none"><a href="https://www.abundance360.com/about" aria-haspopup="true" aria-expanded="false" role="menuitem">About</a>
                                    <ul role="menu" className="hs-menu-children-wrapper">
                                        <li className="hs-menu-item hs-menu-depth-2" role="none"><a href="https://www.abundance360.com/membership" role="menuitem">Membership</a></li>
                                        <li className="hs-menu-item hs-menu-depth-2" role="none"><a href="https://www.abundance360.com/summit" role="menuitem">Summit</a></li>
                                        <li className="hs-menu-item hs-menu-depth-2" role="none"><a href="https://www.abundance360.com/womens-program" role="menuitem">Women's Program</a></li>
                                        <li className="hs-menu-item hs-menu-depth-2" role="none"><a href="https://www.abundance360.com/womens-fellows-program" role="menuitem">Women's Fellows Program</a></li>
                                    </ul></li>
                                <li className="hs-menu-item hs-menu-depth-1 hs-item-has-children" role="none"><a href="#" aria-haspopup="true" aria-expanded="false" role="menuitem">Explore More</a>
                                    <ul role="menu" className="hs-menu-children-wrapper">
                                        <li className="hs-menu-item hs-menu-depth-2" role="none"><a href="https://www.abundance360.com/nomination" role="menuitem">Nominate</a></li>
                                        <li className="hs-menu-item hs-menu-depth-2" role="none"><a href="https://www.abundance360.com/faq" role="menuitem">FAQ</a></li>
                                        <li className="hs-menu-item hs-menu-depth-2" role="none"><a href="https://www.abundance360.com/terms-24" role="menuitem">Terms</a></li>
                                        <li className="hs-menu-item hs-menu-depth-2" role="none"><a href="https://www.diamandis.com/podcast?__hstc=53304183.fee9aeb0d7bcfa7e72dc519bea2fb5db.1708459963841.1708692478739.1708705267782.4&amp;__hssc=53304183.3.1708705267782&amp;__hsfp=3192303745" role="menuitem">"Moonshots with Peter Diamandis" Podcast</a></li>
                                    </ul></li>
                            </ul>
                        </div></span>
                    </div>
                </div>
            </div>
        </footer>
    </div>)
}

export default function HomePageLayout(props: HomePageLayoutProps) {
    return (
        <div id="homePageLayout">
            <PageHeader />
            <main id="pageBody" className="min-h-[100svh] mt-32">
                {props.children}
            </main>
            <PageHooter />
        </div>
    )

}