import React, { useState } from 'react';
import RenewalMembership, { ActionBtn, RenewalMembershipProps } from './RenewalMembership';
import { Deal } from '../../types/deal.type';
import useStripeRenewalPayment from '../../hooks/stripe-renewal-payment';
import StripeProductIds from '../../constants/stripe-products';
import { RenewalLevel } from '../../constants/data.enums';
import { RequestInvoiceForm } from '../RequestInvoiceForm';

const GIPMembership: React.FC<{ deal: Deal }> = ({ deal }) => {
    const { initPayment, error } = useStripeRenewalPayment({ dealId: deal.hubspot_deal_id })
    const [showInvoice, setShowInvoice] = useState(false)
    const [btnStatuses, setBtnStatuses] = useState<Record<number, boolean>>({ 1: false, 2: false, 3: false, 4: false, 5: false })


    const patchBtnStatuses = (newState: Record<number, boolean>) => {
        setBtnStatuses((curState) => {
            return { ...curState, ...newState }
        })
    }

    const config: RenewalMembershipProps = {
        title: 'GENERAL IN PERSON',
        renewalOptions: {
            priceAfterwards: '25,000',
            oneYearFee: '23,000',
            sixYearFee: '20,000'
        },
        commitTo: {
            discountedFee: '23,000',
            fee: '25,000',
            save: '2000',
            year: '2025',
            actionBtns: [
                {
                    text: 'SECURE YOUR SPOT NOW',
                    submitting: btnStatuses[1],
                    onClick() {
                        patchBtnStatuses({ 1: true });
                        initPayment(StripeProductIds[RenewalLevel.GIP].id).finally(() => {
                            patchBtnStatuses({ 1: false });
                        })
                    }
                }, ...(deal.allow_invoice === 'true' ? [{
                    text: 'GET INVOICE FOR WIRE TRANSFER', submitting: btnStatuses[2], onClick() {
                        setShowInvoice(true)
                    },
                }] : []) as ActionBtn[],
                ...(deal.allow_deposit === 'true' ? [
                    {
                        text: 'SECURE YOUR SPOT WITH DEPOSIT NOW',
                        submitting: btnStatuses[4],
                        onClick() {
                            patchBtnStatuses({ 4: true });
                            initPayment(StripeProductIds[RenewalLevel.GIP + 'Deposit'].id).finally(() => {
                                patchBtnStatuses({ 4: false });
                            })
                        }
                    }
                ] : []) as ActionBtn[]],
            desc: deal.allow_deposit === 'true' ? <>
                Members with an A360 <i className="underline">start date on or after November 1, 2023,</i> are eligible to place a $10,000 deposit rather than pay in full. Deposit is only possible through credit card or ACH. Deposit is refundable for 30 days (minus admin fees). Thereafter, non-refundable. <i className="underline">The remaining balance is due on October 15, 2024, which will be automatically charged to your card on file.</i>
            </> : <></>
        },
        commitThru: {
            discountedFee: '20,000',
            fee: '25,000',
            save: '30,000',
            year: '2030',
            actionBtns: [{
                text: 'SECURE YOUR SPOT NOW',
                submitting: btnStatuses[5],
                onClick: () => {
                    patchBtnStatuses({ 5: true });
                    initPayment(StripeProductIds[RenewalLevel.GIP + 'Bundle'].id).finally(() => {
                        patchBtnStatuses({ 5: false });
                    })
                }
            }],
            desc: <>
                Members who commit to Peter and the Abundance Community for the long term freeze their pricing at the rate of $20,000/year for the next 6 years. First year membership for the 6-year bundle must be paid in full. Thereafter, Membership is paid one year at a time. You can cancel whenever you desire, but should you re-enter the membership, you will do so at an escalated price. We accept only ACH or credit card payments. The next Membership Payment is due and will be auto charged on March 1, 2025, for the 2026 membership, and thereafter on the March 1st anniversary through 2029.
            </>
        }
    }

    if (showInvoice) {
        return (<div className='mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-12 lg:px-0'>
            <h1 className="text-center text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Request Invoice for payment</h1>
            <RequestInvoiceForm deal={{ deal }} setShowInvoice={setShowInvoice} />
        </div>)
    } else {
        return <RenewalMembership {...config} paymentError={error} />
    }
}

export default GIPMembership