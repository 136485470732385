type StripeProduct = {
    readonly id: string
    readonly desc: string
}
const StripeProductIds: Readonly<Record<string, StripeProduct>> = {
    Virtual: { id: '2627357980', desc: '2025 Annual Abundance360 Global Virtual Membership' },
    VirtualBundle: { id: '2672557135', desc: '2025 Annual Abundance360 Global Virtual Membership - Bundle' },
    GIP: { id: '2627498833', desc: '2025 Annual Abundance360 General In Person Membership' },
    GIPBundle: { id: '2672581650', desc: '2025 Annual Abundance360 General In Person Membership - Bundle' },
    GIPDeposit: { id: '2536379511', desc: '2025 Annual Abundance360 General In Person Membership - Deposit' },
    Patron: { id: '2627357981', desc: '2025 Annual Abundance360 Patron Membership' },
    PatronBundle: { id: '2672557131', desc: '2025 Annual Abundance360 Patron Membership - Bundle' },
    PatronDeposit: { id: '2627357992', desc: '2025 Annual Abundance360 Patron Membership - Deposit' },
}

export default StripeProductIds


