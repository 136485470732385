import { CheckCircleIcon } from "@heroicons/react/20/solid";
import { isValidElement } from "react";

export interface ActionBtnsConfig {
    postitiveBtnText?: string
    positiveBtnClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
    negativeBtnText?: string
    negativeBtnClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

export interface AlertBoxProps {
    type: 'success' | 'error'
    icon?: React.ReactElement
    title: React.ReactNode
    message: React.ReactNode
    actionBtns?: React.ReactElement | ActionBtnsConfig
}

export default function AlertBox(props: AlertBoxProps) {
    const { icon, title, message, actionBtns } = props;

    const typedActionBtns = actionBtns as ActionBtnsConfig
    return (<div className="max-w-4xl mx-auto">
        <div className="text-green-50 text-green-100 text-green-200 text-green-300 text-green-400 text-green-700 text-green-900 hidden"></div>
        <div className="text-red-50 text-red-100 text-red-200 text-red-300 text-red-400 text-red-700 text-red-900 hidden"></div>
        <div className="p-4 bg-green-50 rounded-md">
            <div className="flex">
                <div className="shrink-0">
                    {
                        isValidElement(icon)
                            ?
                            icon
                            :
                            <CheckCircleIcon className="icon text-green-300" />
                    }
                </div>
                <div className="ml-3 text-left">
                    {
                        isValidElement(title)
                            ?
                            title
                            :
                            <h3 className=" text-green-900 font-medium text-xl">{title}</h3>
                    }
                    <div className=" text-green-700 opacity-75 font-normal text-md mt-2">
                        {
                            isValidElement(message)
                                ?
                                message
                                :
                                <p className="m-0">{message}</p>
                        }
                    </div>
                    <div className="mt-4 ">
                        {
                            isValidElement(actionBtns)
                                ?
                                actionBtns
                                :
                                actionBtns && <div className="flex text-green-900 font-medium text-md gap-2 -mx-2 -my-1">
                                    {
                                        typedActionBtns.positiveBtnClick && typedActionBtns.postitiveBtnText &&
                                        <button onClick={typedActionBtns.positiveBtnClick} className="py-2 px-3 rounded-md bg-green-200 hover:bg-green-300 active:bg-green-400">{typedActionBtns?.postitiveBtnText}</button>
                                    }
                                    {
                                        typedActionBtns.negativeBtnClick && typedActionBtns.negativeBtnText &&
                                        <button onClick={typedActionBtns.negativeBtnClick} className="py-2 px-3 rounded-md bg-green-200 hover:bg-green-300 active:bg-green-400">{typedActionBtns?.negativeBtnText ?? 'Dismiss'}</button>
                                    }
                                </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}